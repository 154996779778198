<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>
        Room
        <span class="red--text">{{
          $store.state.rooms.all.totalItems | formatMoney
        }}</span>
      </v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-toolbar-title>
        Sit Filled
        <span class="yellow--text">{{
          $store.state.rooms.all.sitRoomCount | formatMoney
        }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-toolbar flat>
      <v-toolbar-title>Rooms</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="$store.state.rooms.isEnabledSelect"
        :items="isEnabledItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Is Enable"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="$store.state.rooms.isPrivateSelect"
        :items="isEnabledItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Is Private"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="$store.state.rooms.startPlaySelect"
        :items="startPlayGameItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Is Playing"
      ></v-combobox>
      <v-combobox
        v-model="$store.state.rooms.startGameSelect"
        :items="startGameItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Is Start Game"
      ></v-combobox>
      <v-combobox
        v-model="$store.state.rooms.sitFilledSelect"
        :items="sitsFilledItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Is Sits Filled"
      ></v-combobox>
      <v-combobox
        v-model="$store.state.rooms.roomTypeSelect"
        :items="roomTypes"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Rooms Type"
      ></v-combobox>
      <v-combobox
        v-model="$store.state.rooms.roomFilterSelect"
        :items="roomFilterItems"
        item-value="minBuy"
        item-text="minBuy"
        @change="initialize"
        hide-details
        label="Min Buy"
      ></v-combobox>
      <v-combobox
        v-model="$store.state.rooms.pageItemSelect"
        :items="pageItems"
        item-value="id"
        item-text="value"
        @change="resetPage"
        hide-details
        label="Page"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="this.$store.state.rooms.all.data"
      :loading="this.$store.state.progress"
      :page.sync="page"
      :options.sync="options"
      :disable-sort="true"
      :items-per-page="$store.state.rooms.pageItemSelect.id"
      :server-items-length="this.$store.state.rooms.all.totalItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item.sitsFilled="{ item }">
        <v-expansion-panels
          v-if="item.sitsFilled > 0"
          style="width: 500px; margin:5px 0;"
        >
          <v-expansion-panel style="max-width: 500px;">
            <v-expansion-panel-header>
              <v-progress-linear
                buffer-value="100"
                v-if="item.sitsFilled > 0"
                :value="(100 / item.maxPlayers) * item.sitsFilled"
                color="amber"
                height="25"
                reactive
              >
                <strong>
                  <span>{{ item.sitsFilled }} /</span>
                  <span>{{ item.maxPlayers }} /</span>
                  <span>{{ item.playerCount }}</span>
                </strong>
              </v-progress-linear>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="item.roomInfo">
              <div
                v-for="(value, index) in item.roomInfo"
                :key="index"
                style="float:left;margin-right: 10px;    padding: 0 5px;"
              >
                <v-img
                  width="25"
                  style="margin: 0 auto;"
                  :src="
                    `https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Android_robot.png/504px-Android_robot.png`
                  "
                />
                <div>
                  <div style="text-align:center;">{{ value.name }}</div>
                  <div style="color:yellow">{{ value.chip | formatMoney }}</div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template v-slot:item.ownerInfo="{ item }">
        <UserDetailCard
          v-if="!item.info.isDefault && item.ownerInfo"
          :userId="item.ownerInfo.userId"
          :pictureUrl="item.ownerInfo.picture"
          :userName="item.ownerInfo.username"
          :level="item.ownerInfo.level"
          :flag="item.ownerInfo.flag"
        />
      </template>
      <template v-slot:item.info.minBuy="{ item }">
        <div style="color:yellow;">
          {{ item.info.minBuy | formatMoney }} /
          {{ item.info.maxBuy | formatMoney }}
        </div>
        <div style="color:green;">
          {{ item.info.smallBlind | formatMoney }} /
          {{ item.info.bigBlind | formatMoney }}
        </div>
      </template>

      <template v-slot:item.isStartGame="{ item }">
        <span v-if="item.isStartGame" style="color:yellow;">
          {{ item.isStartGame }}
        </span>
        <span v-else style="color:red;">{{ item.isStartGame }}</span>
      </template>
      <template v-slot:item.isPlaying="{ item }">
        <span v-if="item.isPlaying" style="color:yellow;">
          {{ item.isPlaying }}
        </span>
        <span v-else style="color:red;">{{ item.isPlaying }}</span>
      </template>

      <template v-slot:item.isEnable="{ item }">
        <v-switch
          v-model="item.isEnable"
          @change="changeEnabled(item)"
        ></v-switch>
      </template>
      <template v-slot:item.action="{ item }">
        <div style="width:130px;">
          <v-icon big class="mr-2" @click="disconnectItem(item)"
            >link_off</v-icon
          >
          <v-icon big class="mr-2" @click="editItem(item.info.roomId)"
            >edit</v-icon
          >
          <v-icon big class="mr-2" @click="clearNullPlayer(item)"
            >refresh</v-icon
          >
          <v-icon big class="mr-2" @click="getTax(item)"
            >monetization_on</v-icon
          >
        </div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        @input="pageClick"
        :total-visible="20"
        :length="this.$store.state.rooms.all.totalPage"
      ></v-pagination>
    </div>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Tax Limit</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <!-- <v-col cols="12">
                <v-text-field
                  label="TaxMax"
                  placeholder="TaxMax"
                  v-model="currentLobbyItem.taxMax"
                ></v-text-field>
              </v-col> -->

              <v-col cols="12">
                <v-text-field
                  label="TaxRatio"
                  placeholder="TaxRatio"
                  v-model="currentLobbyItem.taxRatio"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="TrioWinRatio"
                  placeholder="TrioWinRatio"
                  v-model="currentLobbyItem.winRatio"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="setTax">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="detailDialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">Room Detail</span>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="refreshDetail"
              >Refresh</v-btn
            >
          </v-card-actions>
        </v-card-title>

        <v-card-text>
          <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="$store.state.rooms.fakeRoom"
                  label="fakeRoom"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                    <v-btn color="primary" dark @click="saveFakeRoom" class="mt-2"
        >Save Fake Room</v-btn
      >
              </v-col>
            </v-row>
          <v-divider class="mx-2" inset vertical></v-divider>

          <v-container>
            
            <v-row>
              <v-col cols="12">
                <json-viewer
                  name="df"
                  :value="jsonData"
                  :expand-depth="1"
                  theme="my-awesome-json-theme"
                  sort
                ></json-viewer>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="detailDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import UserDetailCard from "../../components/Users/UserDetailCard.vue";

export default {
  components: {
    UserDetailCard,
  },
  data() {
    return {
      page: 1,
      search: "",
      roomFilterItems: [],
      roomTypes: [],
      options: {},
      detailDialog: false,
      pagination: {
        sortBy: "name",
      },
      currentRoomId: 0,
      jsonData: {},
      currentLobbyItem: {},
      dialog: false,
      startGameItems: [
        { id: -1, value: "All" },
        { id: true, value: "Start" },
        { id: false, value: "Stop" },
      ],
      isEnabledItems: [
        { id: -1, value: "All" },
        { id: true, value: "Enabled" },
        { id: false, value: "Disabled" },
      ],
      startPlayGameItems: [
        { id: -1, value: "All" },
        { id: true, value: "Start" },
        { id: false, value: "Stop" },
      ],
      sitsFilledItems: [
        { id: -1, value: "All" },
        { id: 0, value: "Filled" },
      ],
      // roomTypeItems: [
      //   { id: -1, value: "All" },
      //   { id: 0, value: "Holdem" },
      //   { id: 1, value: "Royal" },
      //   { id: 2, value: "Rest" },
      //   { id: 3, value: "JackPot" },
      //   { id: 4, value: "Turbo" },
      //   { id: 5, value: "Jump" },
      //   { id: 6, value: "Arena" },
      // ],
      pageItems: [
        { id: 100, value: "100" },
        { id: 250, value: "250" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
        { id: 2000, value: "2000" },
        { id: 5000, value: "5000" },
      ],
      headers: [
        {
          text: "id",
          align: "left",
          sortable: true,
          value: "info.roomId",
        },
        { text: "private Room", value: "ownerInfo" },
        { text: "name", value: "info.name" },
        { text: "isPlaying", value: "isPlaying" },
        { text: "isStartGame", value: "isStartGame" },
        { text: "SitsFilled", value: "sitsFilled" },
        { text: "LastTurn", value: "lastTurn" },
        { text: "GameType", value: "gameType" },
        { text: "Min Buy", value: "info.minBuy" },
        { text: "TaxMax", value: "info.taxMax" },
        { text: "TaxRatio", value: "info.taxRatio" },
        { text: "TableStep", value: "tableStep" },
        { text: "IsEnable", value: "isEnable", sortable: false },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    resetPage() {
      this.page = 1;
      if (this.options.page == this.page) {
        this.initialize();
      }
      this.options.page = this.page;

      // this.initialize();
    },
    async changeEnabled(val) {
      // eslint-disable-next-line no-console
      console.log(val);

      await this.$store.dispatch("rooms/changeEnabled", val);
    },
    async editItem(roomId) {
      this.jsonData = await this.$store.dispatch("rooms/getRoom", roomId);
      await this.$store.dispatch("rooms/getFakeRoom", roomId);
      // this.jsonData = this.$store.state.rooms.room;
      this.detailDialog = true;
      this.currentRoomId = roomId;
    },

    async saveFakeRoom() {
      await this.$store.dispatch("rooms/postFakeRoom", {
        id: this.currentRoomId,
        value: this.$store.state.rooms.fakeRoom,
      });
    },
    async refreshDetail() {
      this.editItem(this.currentRoomId);
    },
    async clearNullPlayer(item) {
      await this.$store.dispatch("rooms/clearNullPlayer", item.info.roomId);
    },
    async getTax(item) {
      if (item.info.taxMax >= 1000000000000) item.info.taxMax = 1000000000000;
      this.currentLobbyItem = item.info;
      this.dialog = true;
    },
    async setTax() {
      await this.$store.dispatch("rooms/setTax", this.currentLobbyItem);
      this.dialog = false;
    },
    pageClick(a) {
      this.page = a;
      // this.initialize();
    },
    async disconnectItem(item) {
      await this.$store.dispatch("rooms/disconnectPlayer", item.info.roomId);
    },
    async initialize() {
      const { sortBy, sortDesc } = this.options;

      if (sortBy.length === 1 && sortDesc.length === 1) {
        this.$store.state.rooms.all.data = this.$store.state.rooms.all.data.sort(
          (a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              return 0;
            } else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              return 0;
            }
          }
        );
      } else {
        await this.$store.dispatch("rooms/getRooms", {
          search: this.search,
          page: this.page,
        });
      }
    },
  },
  async mounted() {
    await this.$store.commit("rooms/getParameters");
    await this.$store.dispatch("rooms/getRoomFilters");
    await this.$store.dispatch("rooms/getRoomTypes");
    this.roomTypes = this.$store.state.rooms.roomTypes;
    this.roomFilterItems = this.$store.getters["rooms/getRoomFilters"];
    // await this.initialize();
  },
};
</script>

<style>
.v-expansion-panel-content__wrap {
  flex: none;
  margin: 0 auto;
}
</style>
